import {createRouter, createWebHistory} from 'vue-router';

const routes = [
    {
        path: '/',
        name: 'home',
        meta: { title: 'Anchor' },
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    
    {
        path: '/login',
        name: 'login',
        meta: { title: 'Login | Anchor' },
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    },

    {
        path: '/logout',
        name: 'logout',
        meta: { title: 'Logout | Anchor' },
        component: () => import(/* webpackChunkName: "logout" */ '../views/Logout.vue')
    },

    {
        path: '/reset-password',
        name: 'reset-password',
        meta: { title: 'Rest Password | Anchor' },
        component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPassword.vue')
    },
    
    {
        path: '/forgot-password/verify',
        name: 'forgot-password',
        meta: { title: 'Forgot Password | Anchor' },
        component: () => import(/* webpackChunkName: "forgot-password" */ '../views/ForgotPassword.vue')
    },
    

    {
        path: '/onboard',
        name: 'onboard',
        meta: { title: 'Onboard | Anchor' },
        component: () => import(/* webpackChunkName: "onboard" */ '../views/Onboard.vue')
    },

        
    {
        path: '/landing',
        name: 'landing',
        meta: { title: 'Landing | Anchor' },
        component: () => import(/* webpackChunkName: "landing" */ '../views/Landing.vue')
    },

    {
        path: '/creator/get-started',
        name: 'creator--get-started',
        meta: { title: 'Creator Management | Anchor' },
        component: () => import(/* webpackChunkName: "creator-get-started" */ '../views/creator/GetStarted.vue')
    },

    {
        path: '/creator/list',
        name: 'creator--list',
        meta: { title: 'Creator Management | Anchor' },
        component: () => import(/* webpackChunkName: "creator-list" */ '../views/creator/List.vue')
    },

    {
        path: '/programs/applications-list',
        name: 'programs--applications-list',
        meta: { title: 'Applications | Anchor' },
        component: () => import(/* webpackChunkName: "creator-list" */ '../views/programs/ApplicationsList.vue')
    },

    {
        path: '/programs/application/create/:application_id',
        name: 'programs--application-set',
        meta: { title: 'Application | Anchor' },
        component: () => import(/* webpackChunkName: "creator-list" */ '../views/programs/FormSetting.vue')
    },
    {
        path: '/programs/application/:type/:application_id',
        name: 'programs--application-set',
        meta: { title: 'Application | Anchor' },
        component: () => import(/* webpackChunkName: "creator-list" */ '../views/programs/FormSetting.vue')
    },

    {
        path: '/programs/application/:application_id',
        name: 'programs--application',
        meta: { title: 'Application | Anchor' },
        component: () => import(/* webpackChunkName: "creator-list" */ '../views/programs/Application.vue')
    },


    {
        path: '/analytics/overview',
        name: 'analytics--overview',
        meta: { title: 'Analytics | Anchor' },
        component: () => import(/* webpackChunkName: "analytics-overview" */ '../views/analytics/Overview.vue')
    },

    {
        path: '/analytics/product',
        name: 'analytics--product',
        meta: { title: 'Analytics | Anchor' },
        component: () => import(/* webpackChunkName: "analytics-product" */ '../views/analytics/Product.vue')
    },

    {
        path: '/analytics/content',
        name: 'analytics--content',
        meta: { title: 'Analytics | Anchor' },
        component: () => import(/* webpackChunkName: "analytics-content" */ '../views/analytics/Content.vue')
    },

    {
        path: '/analytics/collections',
        name: 'analytics--collections',
        meta: { title: 'Analytics | Anchor' },
        component: () => import(/* webpackChunkName: "analytics-collections" */ '../views/analytics/Collections.vue')
    },

    {
        path: '/analytics/creator',
        name: 'analytics--creator',
        meta: { title: 'Analytics | Anchor' },
        component: () => import(/* webpackChunkName: "analytics-creator" */ '../views/analytics/Creator.vue')
    },

    {
        path: '/request-a-demo',
        name: 'request-for-demo',
        meta: { title: 'Request for Demo | Anchor' },
        component: () => import(/* webpackChunkName: "request-for-demo" */ '../views/RequestForDemo.vue')
    },

    {
        path: '/setting',
        name: 'setting',
        meta: { title: 'Setting | Anchor' },
        component: () => import(/* webpackChunkName: "setting" */ '../views/Setting.vue')
    },


    {
        path: '/settings/custom',
        name: 'setting-custom',
        meta: { title: 'Setting | Anchor' },
        component: () => import(/* webpackChunkName: "setting-desjgn" */ '../views/SettingsDesign.vue')
    },

    {
        path: '/settings/storefront',
        name: 'setting-storefront',
        meta: { title: 'Setting | Anchor' },
        component: () => import(/* webpackChunkName: "setting-desjgn" */ '../views/SettingsDesign.vue')
    },
    // {
    //     path: '/settings/:type/:subtype',
    //     name: 'settings',
    //     meta: { title: 'Settings | Anchor' },
    //     component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
    // },

    {
        path: '/settings/:type',
        name: 'settings',
        meta: { title: 'Settings | Anchor' },
        component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
    },

    {
        path: '/help',
        name: 'help',
        meta: { title: 'Help | Anchor' },
        component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue')
    },

    {
        path: '/account-billing',
        name: 'account-billing',
        meta: { title: 'Account Billing | Anchor' },
        component: () => import(/* webpackChunkName: "help" */ '../views/AccountBilling.vue')
    },

    {
        path: '/:catchAll(.*)',
        name: 'not-found',
        meta: { title: 'Page Not Found | Anchor' },
        component: () => import(/* webpackChunkName: "not-found" */ '../views/NotFound.vue')
    },

    {
        path: '/reports-list',
        name: 'reports',
        meta: { title: 'Reports | Anchor' },
        component: () => import(/* webpackChunkName: "settings" */ '../views/Reports.vue')
    },
]

let base_url = '/';
  
const router = createRouter({
  history: createWebHistory(base_url),
  routes
});

export default router;