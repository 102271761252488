<template>
    <section class="storefront-summary">
        <div class="head" v-if="last_update">
            <div class="top" v-if="!is_hide_date">
                <Checkbox v-if="can_toggle_sortlink" size="small" :key="'all'" :label_text="'Include Shortlink Data'"
                    :checked="sortlink_data" @click="sortLinkClick()" :desc="$store.state.sortlink_desc"
                    :had_svg="true" />

                <!-- <time>Updated: {{ last_update }}</time> -->
                <DatePicker v-show="!is_loading_data && show_date_picker" @update-date="updateDate"
                    :date_range="date_range" ref="date_picker" />
            </div>
            <div class="bot">
                <span>{{ title.title }}</span>
            </div>
        </div>
        <div class="title" v-else>
            <span class="title-span">{{ title.title }}</span>
            <Checkbox v-if="can_toggle_sortlink && !show_last_datetime" size="small" :key="'all'"
                :label_text="'Include Shortlink Data'" :checked="sortlink_data" @click="sortLinkClick()"
                :desc="$store.state.sortlink_desc" :had_svg="true" />
            <div class="update-time" v-if="can_toggle_sortlink && show_last_datetime">Last Update:
                {{ $store.state.user_data.merchant_data_update_hour_ago }}h ago</div>
            <DatePicker v-if="!is_hide_date" v-show="!is_loading_data && show_date_picker" @update-date="updateDate"
                :date_range="date_range" ref="date_picker" />
        </div>
        <div class="chart-box">

            <div class="type-box" v-if="data">
                <SValue :chart_type="chart_type" :chart_type_text="'view'" @clickType="clickType"
                    :sum="data.page_views_sum" :trend="data.page_views_trend" :value_text="title.view"
                    :show_sortlink="false" />

                <SValue :chart_type="chart_type" :chart_type_text="'click'" @clickType="clickType" :sum="data.all_click"
                    :trend="data.click_trend" :value_text="title.click"
                    :show_sortlink="sortlink_data && can_toggle_sortlink" :sort_value="data.short_link_click_sum"
                    :store_value="data.click_sum" />

                <SValue :chart_type="chart_type" :chart_type_text="'tran'" @clickType="clickType" :sum="data.all_sales"
                    :trend="data.sales_trend" :value_text="title.sale"
                    :show_sortlink="sortlink_data && can_toggle_sortlink" :sort_value="data.short_link_sales_sum"
                    :store_value="data.sales_sum" />

                <SValue :chart_type="chart_type" :chart_type_text="'sales'" @clickType="clickType"
                    :sum="data.all_sales_value" :trend="data.sales_value_trend" :value_text="title.sale_value"
                    :show_sortlink="sortlink_data && can_toggle_sortlink"
                    :sort_value="Math.ceil(data.short_link_sales_value_sum)"
                    :store_value="Math.ceil(data.sales_value_sum)" />

            </div>

            <Loading class="summary-loading" v-if="is_loading_data" />
            <!-- v-if="is_loading_data" -->
            <div id="chart_summary" class="chart" ref="chart" v-show="(chart_type === 'view' && data.page_views_sum !== 0) ||
                (chart_type === 'click' && data.all_click !== 0) ||
                (chart_type === 'tran' && data.all_sales !== 0) ||
                (chart_type === 'sales' && data.all_sales_value !== 0)
                "></div>

            <NoData v-if="!is_loading_data
                && ((chart_type === 'view' && data.page_views_sum === 0) ||
                    (chart_type === 'click' && data.all_click === 0) ||
                    (chart_type === 'tran' && data.all_sales === 0) ||
                    (chart_type === 'sales' && data.all_sales_value === 0))
            " :image="require('@/assets/images/data.png')" title="No data available" />

        </div>
    </section>

    <EngagementPosts ref="posts" :is_show_crt="is_show_crt" />
</template>

<script>
import DatePicker from '@/components/ui/DatePicker.vue';
import formatMoney from '@/utils/formatMoney';
import moment from 'moment';
import * as echarts from 'echarts';
import NoData from '@/components/creator/NoData.vue';
import Loading from '../ui/Loading.vue';
import EngagementPosts from '@/components/EngagementPosts.vue';
import Checkbox from '@/components/ui/Checkbox.vue';
import SValue from '@/components/summary/SummaryValue.vue';
import { saveSortLink } from '@/utils/SwitchFun';

export default {
    name: 'StorefrontSummary',
    emits: ['update-data', 'update-creator-modal-date', 'handleSortLink',],

    components: {
        DatePicker,
        NoData,
        Loading,
        EngagementPosts,
        Checkbox,
        SValue,
    },

    data() {
        return {
            option: null,
            chart: null,
            is_loading_data: false,
            is_checked_organic: true,
            is_checked_paid: true,
            data: false,
            data_clicks: [],
            data_sales: [],
            data_transactions: [],
            data_views: [],
            chart_type: 'view',
            is_change: false,
            start_date: moment().add(-30, 'days').toDate(),
            end_date: moment().add(-1, 'days').toDate(),
            can_toggle_sortlink: false,//如果取得的sortlink都是0，要隱藏控制鈕
            sortlink_data: 0,
        }
    },

    props: {
        is_hide_date: {
            type: Boolean,
            default: false,
        },

        is_show_posts: {
            type: Boolean,
            default: true,
        },

        last_update: {
            type: String,
            default: '',
        },

        user_id: {
            type: [String, Number],
            default: '',
        },

        show_date_picker: {
            type: Boolean,
            default: false,
        },

        date_range: {
            type: Object,
            default: null,
        },
        title: {
            type: Object,
            default: {
                title: 'Storefront Summary',
                view: 'Page Views',
                click: 'Clicks',
                sale: 'Sales',
                sale_value: 'Sales Value'
            },
        },
        api_url: {
            type: String,
            default: '/analytics/merchant-account-statistic',
        },
        product_id: {
            type: [String, Number],
            default: '',
        },
        products_num: {
            type: [Object, Array],
            default: [],
        },
        is_show_crt: {
            type: Boolean,
            default: false
        },
        init_sortlink: {
            type: [Boolean, Number],
            default: undefined
        },

        show_last_datetime: {
            type: Boolean,
            default: false
        },

        one_product: {
            type: Boolean,
            default: false
        }
    },

    watch: {
    },

    computed: {

    },

    methods: {
        formatMoney: formatMoney,

        sortLinkClick() {
            this.sortlink_data = this.sortlink_data ? 0 : 1;
            this.chart_type = 'view'
            this.$emit('handleSortLink', this.sortlink_data);//傳出去讓外層也可以拿這值做事情(但外層可能用不到);
            this.getData();
        },

        setOption() {
            this.option = {};
        },

        getData(type = 'view') {
            if (this.$store.state.user_data.merchant_account_id) {
                let s = moment(this.start_date).format('YYYY-MM-DD');
                let e = moment(this.end_date).format('YYYY-MM-DD');

                let date_time = `?start_date=${s}&end_date=${e}`;
                let create_id = this.user_id ? `&creator_user_id=${this.user_id}` : '';
                let product_id = this.product_id ? `&product_id=${this.product_id}` : '';

                //因為不管如何我都要先知道有sortlink=1的時候有沒有資料，所以這邊強行都CALL 1，但顯示不顯示由前端操控
                let api = `${this.api_url}${date_time}${create_id}${product_id}&is_include_shortlink_data=1`;

                // let api = `/analytics/merchant-account-statistic?type=${type}`;
                // if (this.user_id) api = `${api}&user_id=${this.user_id}`;

                this.is_loading = true;
                this.is_loading_data = true;

                this.$ajax(api, {
                    method: 'get',
                    withCredentials: true,
                }).then(res => {
                    let sv = res.data.data.sales_value_rows;

                    for (let i in sv) {
                        sv[i].total_count = Number(sv[i].total_count).toFixed(2);
                    }

                    this.data = res.data.data;
                    let da = this.data;

                    //8882
                    if (this.sortlink_data) {
                        this.data.all_click = da.click_sum + da.short_link_click_sum;
                        this.data.all_sales = da.sales_sum + da.short_link_sales_sum;
                        this.data.all_sales_value = this.one_product ? formatMoney(da.sales_value_sum + da.short_link_sales_value_sum) : Math.ceil(da.sales_value_sum + da.short_link_sales_value_sum);
                    } else {
                        this.data.all_click = da.click_sum;
                        this.data.all_sales = da.sales_sum;
                        this.data.all_sales_value = this.one_product ? formatMoney(da.sales_value_sum) : Math.ceil(da.sales_value_sum);
                    }

                    this.can_toggle_sortlink = da.short_link_click_sum + da.short_link_sales_sum + da.short_link_sales_value_sum;

                    let data = [];
                    let date = [];

                    data = this.handleChartZone(this.data.page_views_rows).data;
                    date = this.handleChartZone(this.data.page_views_rows).date;
                    for (let i in date) {
                        date[i] = moment(date[i]).format('MMM DD, YYYY');
                    }
                    this.option.xAxis[0].data = date;
                    this.option.series[0].data = data;

                    const chart = echarts.init(this.$refs.chart);
                    chart.setOption(this.option);
                    this.is_loading = false;
                    this.is_loading_data = false;
                    this.clickType(this.chart_type);
                    console.log('this.chart_type', this.chart_type)

                    if (this.is_show_posts && this.$store.state.user_data.is_motom_internal_user) {
                        chart.on('click', (params) => {
                            if (params.name) {
                                this.$refs.posts.show(params, this.user_id ? this.user_id : null, date, { start_date: moment(this.start_date).format('YYYY-MM-DD'), end_date: moment(this.end_date).format('YYYY-MM-DD') });
                            }
                        });
                    }

                    this.$emit('update-data', res.data);
                });
            }
        },

        initOption() {
            let vi = this;
            this.sortlink_data = this.init_sortlink !== undefined ? this.init_sortlink : this.$store.state.save_sortlink_data;//放入預設

            this.option = {
                color: ['#000000'],
                tooltip: {
                    show: true,
                    trigger: 'axis',
                    extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3); padding: 5px 10px; font-size: 12px; line-height: 20px; border-radius: 4px;',
                    axisPointer: {
                        type: 'line',
                        snap: true,
                        lineStyle: {
                            width: 1,
                            color: '#232A60',
                            type: 'solid',
                        },
                        label: {
                            componentType: 'series',
                            show: true,
                            backgroundColor: '#78CBFA'
                        },
                    },
                    formatter: function (params) {
                        let content = moment(params[0].axisValue).format('MMM DD, YYYY') + '<br>';
                        let title = '●';
                        if (vi.chart_type == 'sales'){
                            title = '$';
                        }
                            
                        params.forEach(function (item, key) {
                            let which = key === 0 ? 'From storefront' : 'From shortlinks';
                            //one_product
                            if (vi.chart_type === 'view' || !vi.sortlink_data) {

                                if (vi.chart_type == 'sales'){
                                    let val = vi.one_product ? formatMoney(item.value) : Math.ceil(item.value).toLocaleString();
                                    content += `${title} ${val} <br>`;
                                }else{
                                    content += `${title} ${Math.ceil(item.value).toLocaleString()} <br>`;
                                }
                                
                            } else {
                                if (vi.chart_type == 'sales') {
                                    let val = vi.one_product ? formatMoney(item.value) : Math.ceil(item.value).toLocaleString();
                                    content += `${which}: $${val} <br>`;
                                } else {
                                    content += `${which}: ${Number(item.value).toLocaleString()} <br>`;
                                }

                            }

                        });
                        return content;
                    },
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        axisLabel: {
                            formatter: (params) => {
                                return moment(params).format('MMM DD')
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        alignTicks: false,
                    }
                ],
                series: [
                    {
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        symbol: 'circle',
                        symbolSize: 20,
                        itemStyle: {
                            color: '#232A60',
                            borderWidth: 6,
                            borderColor: 'rgba(176, 216, 246)',
                        },
                        triggerLineEvent: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            // opacity: 0.8,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: '#5789EB'
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(120, 203, 250, 0.7)'//'#78CBFA'
                                }
                            ])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: []
                    },
                    //2
                    {
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        symbol: 'circle',
                        symbolSize: 20,
                        itemStyle: {
                            color: '#232A60',
                            borderWidth: 6,
                            borderColor: 'rgba(176, 216, 246)',
                        },
                        triggerLineEvent: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            // opacity: 0.8,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: '#EDDE54'
                                },
                                {
                                    offset: 1,
                                    color: '#edde54a5'//'#78CBFA'
                                }
                            ])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: []
                    },
                ]
            };
        },

        resizeChart() {
            this.$nextTick(() => {
                if (this.$refs.chart) {
                    const chart = echarts.init(this.$refs.chart);
                    chart.setOption(this.option);
                    chart.resize();
                }
            })

        },

        handleChartZone(arr) {
            let data = [];
            let date = [];
            let last = null;

            arr.forEach(item => {

                const pushData = () => {
                    data.push(item.total_count);
                    date.push(item.event_date);
                    last = item.event_date;
                }

                if (last !== item.event_date) {
                    pushData()
                } else {
                    data.splice(-1, 1);
                    date.splice(-1, 1);
                    pushData();
                }
            })
            return { data: data, date: date }
        },

        clickType(type) {
            this.chart_type = type;
            let data = [];
            let date = [];
            let o = {};
            let data2 = [];

            switch (type) {
                case 'view':
                    o = this.handleChartZone(this.data.page_views_rows);
                    break;

                case 'click':
                    o = this.handleChartZone(this.data.clicks_rows);
                    data2 = this.handleChartZone(this.data.short_link_clicks_rows).data;
                    break;

                case 'tran':
                    o = this.handleChartZone(this.data.sales_rows);
                    data2 = this.handleChartZone(this.data.short_link_sales_rows).data;
                    break;

                case 'sales':
                    o = this.handleChartZone(this.data.sales_value_rows);
                    data2 = this.handleChartZone(this.data.short_link_sales_value_rows).data;
                    break;

            }

            // console.log(this.data.page_views_rows)

            data = o.data;
            date = o.date;

            for (let i in date) {
                date[i] = moment(date[i]).format('MMM DD, YYYY');
            }
            this.option.xAxis[0].data = date;
            this.option.series[0].data = data;
            //2
            this.option.series[1].data = this.sortlink_data ? data2 : [];

            const chart = echarts.init(this.$refs.chart);
            chart.setOption(this.option);
        },

        updateDate(obj) {
            this.start_date = obj.start_date;
            this.end_date = obj.end_date;
            this.getData();
            this.$emit('update-creator-modal-date', obj);
        },
    },

    mounted() {
        this.initOption();
        // console.log(this.date_range)
        if (this.date_range) {
            // this.$refs.date_picker.custom_date = [this.date_range.start_date, this.date_range.end_date];
            this.start_date = this.date_range.start_date;
            this.end_date = this.date_range.end_date;

            // setTimeout(() => {
            //     this.$refs.date_picker.setDate([this.date_range.start_date, this.date_range.end_date]);

            // }, 1000);
        }

        this.getData();

        this.EventBus.off('update-date-at-analytics');
        this.EventBus.on('update-date-at-analytics', (obj) => {
            this.updateDate(obj);
        });
    },

    created() {
        window.addEventListener('resize', this.resizeChart);
    },

    destroyed() {
        window.removeEventListener('resize', this.resizeChart);
    },
}
</script>
<style lang="scss">
.storefront-summary {
    margin-bottom: 24px;

    .head {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row-reverse;

        .top {
            display: flex;
            align-items: center;
            margin: 16px 0px 8px 8px;
            pointer-events: none;
            justify-content: flex-end;
            position: relative;
            //margin-top: -56px;
            height: 32px;


            & * {
                pointer-events: auto;
            }

            .ui-checkbox {
                margin-right: 8px;
            }
        }

        .bot {
            margin: 16px 0 16px 0;
            display: flex;
            align-items: center;
            flex: 1;

            &>span {
                font: var(--font-demi-20);
                color: var(--black-90);
                flex: 1;
            }
        }

        @media (max-width: 950px) {
            display: block;
        }
    }

    .title {
        display: flex;
        align-items: center;
        min-height: 38px;

        .title-span {
            font: var(--font-demi-20);
            color: var(--black-90);
        }

        span {
            flex: 1;
        }

        .ui-date-picker {
            margin-left: 8px;
        }

        .update-time {
            margin-right: 4px;
        }
    }

    .chart-box {
        background: var(--bg-02);
        margin-top: 8px;
        overflow: hidden;
        position: relative;

        .creator-no-data img {
            width: 120px;
        }

        .type-box {
            display: flex;
            cursor: pointer;


        }

        .chart {
            width: auto;
            height: 300px;
            padding-bottom: 60px;
        }

        .summary-loading {
            position: absolute;
        }

    }

}
</style>