<template>
    <div :class="classnames" @click.stop="openPost()">

        <!-- {{ data.social_post_type }} -->

        <div class="show-card">
            <!-- (whichPost.reel || whichPost.story || whichPost.feed) || data.social_post_type==='reel' || data.social_post_type==='story'-->
            <div :class="['img-box', (data.social_post_type=='reel' || data.social_post_type=='story') && 'img-box-long']">
                <div class="icon">
                    <SvgIcon :name="data.social_platform === 'tikTok' ? 'tiktok-pure' : 'instagram'" />
                </div>
                <img :src="data.thumbnail_url" @error="imageError($event)" alt="avatar" />
                <Badage class="img-bagage"
                    v-if="data.social_post_type === 'reel' || data.social_post_type === 'story' || badageText"
                    :value="badageText" :type="'black'" :border="'4'" />

                <div class="img-bag" v-if="data.product_count">
                    <div class="svg-box">
                        <svg-icon name="bag"/>
                        <span>{{ data.product_count }}</span>
                    </div>
                </div>
            </div>


            <div class="card">
                <div class="it date">
                    {{ $filters.momentFormat(data.date, 'll') }}
                    <span v-if="!data.is_exist&& data.social_post_type=='story'" class="arc">Archived</span>
                    <span v-else-if="data.is_public">Live</span>
                    <span v-else class="unav">Unavilable</span>
                </div>
                <div class="it-vi avatar-box" v-if="creator_show" @click.stop="goProfile(data)">
                    <figure class="avatar">
                        <img v-if="data.social_avatar" :src="data.social_avatar" @error="imageError($event)" />
                        <img v-else src="@/assets/images/user.png" />
                    </figure>
                    {{ data.nickname }}
                </div>
                <div class="it hr"></div>

                <div v-if="(data.social_platform !=='instagram') || (data.social_platform ==='instagram' && data.social_account_connect_status !==10)">
                    <div class="it it-vi views" v-if="data.social_post_type === 'reel'">{{ Number(data.views).toLocaleString('en-US') }}<span>Plays</span></div>
                    <div class="it it-vi" v-if="data.social_post_type !== 'reel' && data.social_platform !== 'tikTok'">{{ Number(data.impressions).toLocaleString('en-US') }}<span>Impressions</span></div>
                    
                    <div class="it it-vi" v-if="data.social_platform !== 'tikTok'">{{ Number(data.reach).toLocaleString('en-US') }}<span>Reach</span></div>
                    <div class="it it-vi views" v-if="!whichPost.img && data.social_post_type !== 'story' && data.social_post_type !== 'reel' && data.social_post_type !== 'image'">{{ Number(data.views).toLocaleString('en-US') }}<span>Views</span>
                    </div>

                    

                    <div class="it it-vi views" v-if="whichPost.img && data.social_post_type === 'video'">{{ Number(data.views).toLocaleString('en-US') }}
                        <span>Video Views</span>
                    </div>
                    <div class="it it-vi eng">{{ Number(data.engagement).toLocaleString('en-US') }}<span>Engagements</span></div>
                    <div class="it it-vi">{{ handleNumSpec(data.engagement_rate) === '---' ? `0%` : `${handleNumSpec(data.engagement_rate)}%`}}<span>Engagement Rate</span>
                    </div>

                    <div class="it icon-box" v-if="data.social_post_type!=='story'">
                        <div class="icon-item" v-for="(v, k) in icon_list" :key="k">
                            <SvgIcon :name="v.icon" />
                            {{ v.value }}
                        </div>
                    </div>
                </div>

                <div v-else class="empty-box">
                    <div class="empty">Social data unavailable based on lack of creator permissions.</div>
                </div>
                <template v-if="data.tags">
                    <div class="it hr" v-if="((!no_plus || data.is_brand_post) && $store.state.user_data.contract_status) || (!$store.state.user_data.contract_status && data.tags.length > 0) || data.tags.length > 0"></div>
                    <div class="tag-title" v-if="((!no_plus || data.is_brand_post) && $store.state.user_data.contract_status) || (!$store.state.user_data.contract_status && data.tags.length > 0)">Content Tag</div>
                    <div class="tagging-list-box">

                        <swiper class="it swiper-box label-box" :modules="modules" :slides-per-view="'auto'" ref="swiper"
                            :slides-per-group="3" v-if="data.tags.length > 0">
                            <swiper-slide v-for="(v, k) in data.tags" :key="k">
                                <Badage :value="v.text" :type="'gray'" />
                            </swiper-slide>
                        </swiper>

                        <div class="btn add-i" v-if="!add_value.loading && (!no_plus || data.is_brand_post) && ($store.state.user_data.contract_status)" @click.stop="openTag()">
                            <i><svg-icon name="plus" /></i>
                            <span>Content Tag</span>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <DropdownHashtag 
            :tags="tag_select" :otherTag="otherTag" :selected_tags="data.tags" :no_all="true"
            @select-tag="selectTag" ref="pop" :is_anchor_post="data.is_anchor_post===1"/>
    </div>

</template>

<script>
import SvgIcon from '@/components/ui/SvgIcon.vue';
import Badage from '@/components/ui/Badage.vue';
import Button from '@/components/ui/Button.vue';
import { ImageError } from '@/utils/SwitchFun';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/scss';
import { Navigation } from 'swiper/modules';
import { HandleNumSpec , HandleNum } from '@/utils/SwitchFun';
import DropdownHashtag from '@/components/DropdownHashtag.vue';

export default {
    name: 'creator-small-card',

    data() {
        return {
            add_value: {
                loading: false,
            },
            show_tag:false,
        };
    },

    setup() {
        return {
            modules: [Navigation],
        };
    },


    components: {
        SvgIcon,
        Badage,
        Button,
        Swiper,
        SwiperSlide,
        DropdownHashtag,
    },

    computed: {
        classnames() {
            return `post-item ${this.classname}`
        },
        icon_list() {
            let list = [
                { icon: 'card-like', value: Number(this.data.likes).toLocaleString('en-US') },
                { icon: 'card-repeat', value: Number(this.data.comments).toLocaleString('en-US') },
                { icon: 'card-mark', value: Number(this.data.saved).toLocaleString('en-US') },
                { icon: 'card-share', value: Number(this.data.shares).toLocaleString('en-US') },
            ]
            if (this.data.social_platform === 'tikTok') {
                return list.filter((e, k) => { return k !== 2 })
            }
            else if (this.data.social_platform !== 'tikTok' && this.whichPost.story) {
                return list.filter((e, k) => { return k <= 1 })
            }
            else {
                return list
            }
        },
        whichPost() {
            return {
                img: this.posts_value && this.posts_value.top_menu_index === 0,
                vedio: this.posts_value && this.posts_value.top_menu_index === 3,
                reel: this.posts_value && this.posts_value.top_menu_index === 1,
                story: this.posts_value && this.posts_value.top_menu_index === 2,
                feed: false,
            }
        },
        badageText() {
            if (this.whichPost.img || (this.data.social_platform ==='instagram' && this.data.social_post_type!=='reel' && this.data.social_post_type!=='story' )) {
                return 'In-Feed'
            } else if (this.whichPost.reel || this.data.social_post_type==='reel') {
                return 'Reel'
            } else if (this.whichPost.story || this.data.social_post_type==='story') {
                return 'Stories'
            } else {
                return '';
            }

        }
    },

    props: {
        classname: {
            type: String,
            default: ''
        },
        data: {
            type: Object,
            default: {}
        },

        tag_select: {
            type: Array,
            default: {}
        },

        no_plus: {
            type: Boolean,
            default: false
        },
        nowIg_tab: {
            type: Number,//ig post=>0 ,reel=>1 story=>2 ,tk=>3
            default: 0
        },
        creator_show: {
            type: Boolean,
            default: false,
        },
        otherTag:{
            type: Object,
            default: {},
        },

        ambassador_user_application_record_id: {
            type: Number,
            default: 0,
        },

    },
    methods: {
        imageError: ImageError,
        handleNumSpec: HandleNumSpec,
        handleNum:HandleNum,

        openTag(){
            if(this.show_tag){
                this.$refs.pop.close();
                this.show_tag=false;
            }else{
                this.$refs.pop.open(false);
                this.show_tag=true;
            }
        },

        openPost() {
            const obj =  { 
                post: this.data, 
                whichPost: this.whichPost, 
                badageText: this.badageText,
                anchor_creator_id:this.data.anchor_creator_id 
            };

            if (this.ambassador_user_application_record_id) {
                obj.ambassador_user_application_record_id = this.ambassador_user_application_record_id;
            }

            this.EventBus.emit('open-post-modal', obj);
        },

        imageError(event) {
            event.target.src = require('@/assets/images/user.png');
        },

        selectTag(item) {
            if (this.data.tags.filter((o) => o.id === item.id).length !== 0) {
                const index = this.data.tags.findIndex(tag => tag.id === item.id);
                this.data.tags.splice(index, 1);

                // console.log('remove => ' + index);
            } else {
                this.data.tags.push(item);
            }

            let tag_ids = this.data.tags.map((o) => o.id);
            tag_ids=this.handleDisplayStorefront(tag_ids);

            this.$ajax(`creator/update-social-post-tag`, {
                method: 'post',
                data: {
                    post_ids: [this.data.id],
                    tag_ids: tag_ids,
                }
            });

        },

        handleDisplayStorefront(tag_ids){
            // 1. 移除 tag_ids 中的 -2(後端也是-2)
            const index = tag_ids.indexOf(-2);

            // 2. 判断 this.data.display_anchor_post_tag 并进行 API 调用
            if (this.data.display_anchor_post_tag === 0) {
                if (index !== -1) {
                    // tag_ids 包含 -2 且 display_anchor_post_tag === 0 时
                    this.callDisplayStorefrontApi(1);
                }
            } else {
                if (index === -1) {
                    // tag_ids 不包含 -2 且 display_anchor_post_tag !== 0 时
                    this.callDisplayStorefrontApi(0);
                }
            }

            return tag_ids.filter(e=>e !==-2 );
        },

        callDisplayStorefrontApi(id){
            this.$ajax(`creator/update-anchor-post-tag`, {
                method: 'post',
                data: {
                    post_ids: [this.data.id],
                    display_anchor_post_tag: id,
                }
            });
        },

        editTag(obj) {
            this.$emit('edit-tag', obj);
        },

        goProfile(data){
            let new_data={...data};
            new_data.id=new_data.anchor_creator_id;
            new_data.user_id=new_data.anchor_creator_id;
                
            if(this.$store.state.creator_data_open){
                this.EventBus.emit('update-modal-creator',new_data)
            }else{
                setTimeout(() => {
                    this.EventBus.emit('open-creator-modal',new_data);
                },10);
            }

        }
    },

    mounted() {
        //this.data.display_anchor_post_tag 0為不顯示
        if (typeof this.data.tags !== 'undefined' && this.data.tags.length >= 0 && this.data.display_anchor_post_tag !== 0 ) {
            this.data.tags.unshift({ id: -2, text: "In Storefront" });
            // 检查并移除重复项
            this.data.tags = this.data.tags.filter((tag, index, array) => {
                // 查找第一个匹配的项
                const firstIndex = array.findIndex(t => t.id === -2 && t.text === "In Storefront");
                return !(tag.id === -2 && tag.text === "In Storefront" && index !== firstIndex);
            });
        } else if (typeof this.data.tags !== 'undefined'){
            this.data.tags=[];
        }

        this.EventBus.on('update-post-tags', (data) => {
            if (this.data.id === data.post_id) {
                this.data.tags = data.tags;
            }
        });

        this.EventBus.on('updated-all-post-tag', (tags) => {
            if (this.data.tags) this.data.tags.forEach((tag, tag_index) => {
                const index = tags.findIndex((o) => o.id === tag.id);
                if (index === -1) {
                    // remove this tag
                    this.data.tags.splice(tag_index, 1);
                }
            });
        });
    }

};
</script>

<style lang="scss">
@mixin flexCenter() {
    display: flex;
    align-items: center;
}


.post-item {
    position: relative;
    border-radius: 4px;
    border: solid 1px var(--border);
    //overflow: hidden;
    cursor: pointer;
    background-color: white;
    box-shadow: var(--bot-2dp);
    
    .dropdown-hashtag{
        top:unset;
        bottom:20px;
        left: 24px;
    }

    .icon {
        position: absolute;
        border-radius: 0 0 4px 0;
        background-color: var(--bg-01);
        width: 18px;
        height: 18px;
        @include flexCenter;
        justify-content: center;

        .svg-icon {
            width: 10px;
            height: 10px;
        }
    }

    img {
        width: 100%;
        aspect-ratio: 1/1;
        height: auto;
        object-fit: cover;
    }

    .img-box-long img {
        aspect-ratio: 1/1;//9/16;
    }

    .card {
        padding: 6px;
        color: var(--black-90);
        position: relative;

        .it {
            margin-bottom: 8px;
        }
    }

    .avatar-box {
        @include flexCenter();
        margin-bottom: 4px;
        padding: 2px 0;

        .avatar {
            padding-right: 4px;
            @include flexCenter();

            img {
                width: 26px;
                height: 24px;
                border-radius: 50%;
                border: 1px solid var(--black-40);
            }
        }

        &:hover{
            background-color: var(--bg-08);
        }
    }

    .name-box {
        @include flexCenter();
        font: var(--font-med-14);

        .avatar {
            margin-right: 4px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            overflow: hidden;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

    .date {
        color: var(--black-50);
        font-size: 10px;
        @include flexCenter();
        justify-content: space-between;

        span {
            color: var(--green);

            &.unav {
                color: var(--red);
            }

            &.arc {
                color: var(--black-75);
            }
        }
    }

    .brand {
        color: var(--black-50);
        font: var(--font-med-12);
    }

    .hr {
        background-color: var(--border);
        height: 1px;
        width: 100%;
        border: 0;
    }

    .empty-box{
        height: 120px;
        display: flex;
        align-items: center;
        padding: 24px;

        .empty{
            margin: auto;
            text-align: center;
            font-size: 12px;
            color: var(--black-50);
        }
    }

    .views {
        margin-bottom: 4px;
    }

    .it-vi {
        font: var(--font-med-14);

        span {
            margin-left: 4px;
            font: var(--font-med-10);
        }
    }

    .tag-title{
        margin-bottom: 4px;
        font: var(--font-med-12);
        color: var(--black-50);
    }

    .icon-box {
        @include flexCenter();
        align-items: center;

        .icon-item {
            @include flexCenter();
            margin-right: 12px;
            font: var(--font-med-10);

            .svg-icon {
                width: 10px;
                height: 10px;
                margin-right: 4px;
                fill: var(--black-75);
            }
        }

    }

    .col {
        @include flexCenter();
        justify-content: flex-end;

        span {
            height: 24px;
            width: 24px;
            cursor: pointer;
            @include flexCenter();
            justify-content: center;
            position: relative;
            margin-left: 10px;
            border-radius: 2px;
            transition: all .2s ease-in-out;
            color: var(--black-90);

            &:hover {
                background-color: var(--bg-03);
            }

            svg {
                height: 10px;
                width: 10px;
                position: relative;
                transform: rotate(90deg);
            }

            &.swiper-button-next {
                svg {
                    transform: rotate(-90deg);
                }
            }

            &.swiper-button-disabled {
                cursor: not-allowed;
                color: var(--black-40);
                pointer-events: none;
            }
        }
    }

    .show-card {

        .img-box {
            position: relative;
        }

        .img-bagage {
            position: absolute;
            bottom: 15px;
            right: 8px;
        }

        .swiper{
            width: auto;
            margin: 0 !important;
        }

        .img-bag{
            position: absolute;
            bottom: 15px;
            left: 8px;
            background: var(--black-100);
            font: var(--font-med-12);
            padding: 4px 5px;
            border-radius: 4px;

            .svg-box{
                display:flex;
                align-items: center;
                gap: 6px;
                cursor: pointer;
                color:var(--white);

                .svg-icon{
                    width: 12px;
                    height: 12px;
                    fill:var(--white);
                }
            }
        }
    }

    .label-box {
        //height: 76px;

        .swiper-slide {

            width: auto !important;
            min-width: 26px;
            margin-right: 6px;
            padding: 0 !important;
            overflow: hidden;
            box-sizing: content-box;

        }

        .my-badges-iden {
            margin-right: 0;
            margin-bottom: 0;
            font: var(--font-med-12);
            color: var(--black-90);
            border-radius: 4px;
        }
    }

    .tag-drop-samll .ui-button {
        padding: 0 5px;
        height: 24px;
        border-radius: 6px;
        margin-right: 4px;
        border: solid 1px var(--border);

        svg {
            fill: var(--black-100);
        }
    }

    .ui-dropdown--inner .item-box {
        bottom: 26px;
    }

    .tagging-list-box {
        @include flexCenter();

        .add-i{
            display: flex;
            margin: 0;
            height: unset;
            background-color: unset;
            padding: 3px 6px;
            border: 1px solid var(--black-90);
            color: var(--black-90);
            font: var(--font-med-12);
            text-align: center;
            align-items: center;
            border-radius: 6px;
            cursor: pointer;
            margin-right: 6px;

            .svg-icon{
                fill: var(--black-90);
                width: 8px;
                height: 8px;
                margin-right: 4px;
            }

            span{
                white-space: nowrap;
            }
        }
    }

}
</style>
